<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4>
          <i class="fa fa-list"></i>
          استعراض مشرفين الباصات |
          <b-link to="/bus/admins/add" class="btn btn-relief-success btn-sm">
            <i class="fa fa-user-plus"></i>
            إضافة مشرف جديد</b-link
          >
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>الإسم</th>
              <th>إسم المستخدم</th>
              <th>الهاتف</th>
              <th>ملاحظات</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="admin in admins" :key="admin._id">
                <td>
                  {{ admin.name }}
                </td>
                <td>
                  {{ admin.username }}
                </td>
                <td>
                  {{ admin.phone }}
                </td>
                <td>
                  {{ admin.notes }}
                </td>
                <td>
                  <div v-if="user._id != admin._id">
                    <b-link
                      :to="'/bus/admins/edit/' + admin._id"
                      class="btn btn-relief-success btn-sm"
                    >
                      <i class="fa fa-edit"></i>
                      تعديل
                    </b-link>
                    &nbsp;
                    <button
                      @click="deleteAdmin(admin._id)"
                      class="btn btn-relief-danger btn-sm"
                    >
                      <i class="fa fa-trash"></i>
                      حذف
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
  created() {
    if (checkPer(this, "admins_bus", "view")) {
      var g = this;
      $.post(api + "/admin/admins/list", {
        jwt: g.user.jwt,
        bus: 1,
      }).then(function (r) {
        g.admins = JSON.parse(r).response;
      });
    }
  },
  data() {
    return {
      admins: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    deleteAdmin(id) {
      var g = this;
      if (confirm("متأكد من حذف المشرف؟")) {
        $.post(api + "/admin/admins/delete", {
          jwt: g.user.jwt,
          id: id,
        }).then(function (r) {
          alert("تم الحذف");
          $.post(api + "/admin/admins/list", {
            jwt: g.user.jwt,
            bus: 1,
          }).then(function (r) {
            g.admins = JSON.parse(r).response;
          });
        });
      }
    },
  },
};
</script>

<style>
</style>